<template>
  <div class="page-wrapper">
    <location-detail
      v-if="currentProduct"
      :product="currentProduct"
    ></location-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocationDetail from '@/components/locations/LocationDetail'

export default {
  components: { LocationDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('locations', ['getProductById']),
    currentProduct() {
      return this.getProductById(this.id)
    }
  }
}
</script>

<style>
@media print {
  .navbar,
  h1 {
    visibility: hidden;
  }
}
</style>
